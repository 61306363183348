import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "control-smilee-chat-behavior-through-windowsmileeservice-api"
    }}>{`Control smilee chat behavior through `}<inlineCode parentName="h1">{`window.smileeService`}</inlineCode>{` api`}</h1>
    <p>{`After Smilee script has been run on the website, three options are available in the browser window.`}</p>
    <p><inlineCode parentName="p">{`window.smileeService.remove()`}</inlineCode>{` - This will remove all of the smilee code from the page`}</p>
    <p><inlineCode parentName="p">{`window.smileeService.load()`}</inlineCode>{` - This will load the chat and make everything ready in the background`}</p>
    <p><inlineCode parentName="p">{`window.smileeService.open()`}</inlineCode>{` - This will open the chat, if the code is not loaded it will load the code before opening the chat`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      